const __getGateways = {
  method: "GET",
  url: "gateway/",
};

const __getGatewaysOld = {
  method: "GET",
  url: "gateway/old",
};

const __getGatewaysStatus = {
  method: "GET",
  url: "gateway/status",
};

const __getGatewayById = (id) => ({
  method: "GET",
  url: `gateway/${id}`,
});

const __createGateway = {
  method: "POST",
  url: `gateway/`,
};

const __updateGateway = (id) => ({
  method: "PUT",
  url: `gateway/${id}`,
});

const __reboot = {
  method: "POST",
  url: `gateway/reboot`,
};

const __scan = {
  method: "POST",
  url: `gateway/scan`,
};

const __save = {
  method: "POST",
  url: `gateway/save`,
};

const __rotateSlot = {
  method: "POST",
  url: `gateway/set_slot_rotation_schedule`,
};

const __disableRotateSlot = {
  method: "POST",
  url: `gateway/remove_slot_rotation_schedule`,
};

const __disable_not_in_use = {
  method: "POST",
  url: `gateway/disable_not_in_use`,
};

const __gatewayDetails = (id) => ({
  method: "GET",
  url: `gateway/${id}/details`,
});

const __getScanHistory = {
  method: "GET",
  url: "scan_history/",
};

const __getRotateSlotHistory = {
  method: "GET",
  url: "scan_history/slot_rotation",
};

const __getRotateSlotHistoryStatus = (id) => ({
  method: "GET",
  url: `scan_history/slot_rotation/${id}`,
});

const __getRotateSlotHistoryExport = (id) => ({
  method: "GET",
  url: `scan_history/slot_rotation/${id}/export`,
});

const __gatewayDetailsExport = (id) => ({
  method: "GET",
  url: `gateway/${id}/details/export`,
});

const __gatewayDetailsExportMulti = {
  method: "GET",
  url: `gateway/details/export`,
};

const __gatewayEnableSlots = {
  method: "POST",
  url: `gateway/enable_gateway_slots`,
};

const __smsTestHistory = {
  method: "GET",
  url: `gateway/sms_test_history`,
};

const __smsTestDetails = (id) => ({
  method: "GET",
  url: `gateway/sms_test_history/${id}`,
});

const __testSmsReceive = (gateway_id) => ({
  method: "POST",
  url: `gateway/${gateway_id}/test_sms_receive`,
});

const __gatewayRebootInterval = {
  method: "POST",
  url: `gateway/set_reboot_interval`,
};

const __stopSlotScanRotation = {
  method: "POST",
};

const __pduPowerCycle = {
  method: "POST",
};

const __getPDUs = {
  method: "GET",
  url: "pdu/",
};

const __getPDUStatus = {
  method: "GET",
  url: "pdu/status",
};

const __getPDUById = (id) => ({
  method: "GET",
  url: `pdu/${id}`,
});

const __createPDU = {
  method: "POST",
  url: `pdu/`,
};

const __updatePDU = (id) => ({
  method: "PUT",
  url: `pdu/${id}`,
});

const __addSMSForwardSetting = {
  method: "POST",
  url: `gateway/add_sms_forward_settings`,
};

const __forbidPLMNCall = {
  method: "POST",
  url: `gateway/forbid_plmn_call`,
};

const __runAtCommands = {
  method: "POST",
  url: `gateway/run_at_commands`,
};

const __fetchGatewayActions = (batch_id) => ({
  method: "GET",
  url: `gateway/gateway-actions/${batch_id}`,
});

const __autoSimRotate = {
  method: "POST",
  url: `gateway/auto_sim_rotate`,
};

export {
  __scan,
  __save,
  __reboot,
  __getPDUs,
  __createPDU,
  __updatePDU,
  __getPDUById,
  __rotateSlot,
  __getGateways,
  __getPDUStatus,
  __runAtCommands,
  __createGateway,
  __updateGateway,
  __pduPowerCycle,
  __autoSimRotate,
  __getGatewaysOld,
  __gatewayDetails,
  __getGatewayById,
  __getScanHistory,
  __smsTestHistory,
  __smsTestDetails,
  __testSmsReceive,
  __forbidPLMNCall,
  __disableRotateSlot,
  __getGatewaysStatus,
  __gatewayEnableSlots,
  __disable_not_in_use,
  __fetchGatewayActions,
  __addSMSForwardSetting,
  __getRotateSlotHistory,
  __gatewayDetailsExport,
  __stopSlotScanRotation,
  __gatewayRebootInterval,
  __gatewayDetailsExportMulti,
  __getRotateSlotHistoryStatus,
  __getRotateSlotHistoryExport,

};
